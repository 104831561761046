.add-button-component {
	width: 40px;
	height: 40px;
	border: 0;
	font-size: 24px;
	transition: all 0.2s ease;
	line-height: 0;
	color: white;
	background-color: $v2-create-button-green;
	&:hover {
		background-color: $v2-create-button-green-active;
	}

	&.disabled {
		background-color: $v2-grey;
	}
}
