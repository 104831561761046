.settings-profile {
	.section-body {
		margin: 16px 24px;
	}
	.back-button {
		cursor: pointer;
		@include back-button;
	}
	.web-fron-version {
		font-size: 12px;
		color: #545454;
		font-style: italic;
	}

	.unit {
		padding-left: 5px;
		display: inline;
	}

	.skills-wrapper {
		width: 500px;
		.selected-labels {
			margin-top: 5px;
			display: flex;
			flex-wrap: wrap;
			> div {
				margin-right: 5px;
				margin-bottom: 5px;
			}
		}
	}

	.organization-div {
		display: flex;
		flex-direction: row;

		.select-wrapper {
			padding-right: 48px;
		}
	}

	.profile-header {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.personal-info {
		.info-header {
			display: flex;
			flex-direction: row;
			@include gear-menu;
		}
		.user {
			margin-top: 24px;
			margin-left: 2em;
			display: inline-block;
			img {
				&.profile-pic-default {
					margin-left: 8.5px;
				}
			}
			.person-initials-hexagon-shape {
				margin-left: 8px;
			}

			.upload-overlay,
			.upload-overlay-hidden {
				height: 140px;
				width: 140px;
				background: rgba(0, 0, 0, 0.75);
				border-radius: 50%;
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 2;
			}
			.upload-overlay-hidden {
				display: none;
			}
			.uploading-gif,
			.uploading-gif-hidden {
				height: 48px;
				width: 48px;
				background-image: url($app-loader-url);
				background-repeat: no-repeat;
				background-size: 48px 48px;
			}

			.controls {
				padding-top: 10px;
				.controls-update {
					cursor: pointer;
					height: 21px;
					::-webkit-file-upload-button {
						cursor: pointer;
					}
					position: relative;
					&:hover {
						color: $color-purple-hover-active;
					}
					input {
						width: 140px;
						margin-top: -5px;
						cursor: pointer;
						opacity: 0;
						position: absolute;
					}
				}

				.control {
					font-size: 14px;
					background-size: 16px;
					background-repeat: no-repeat;
					background-position: left center;
					padding: 5px 10px 0 21px;
					font-weight: $base-font-medium-weight;

					cursor: pointer;
					visibility: hidden;
					&:hover {
						cursor: pointer;
						color: $color-purple-hover-active;
					}
					&.update {
						background-image: url('images/sections/settings/settings-overview/update-photo.svg');
					}
					&.remove {
						background-image: url('images/sections/settings/settings-overview/remove.svg');
					}
				}
			}
			&:hover .control {
				visibility: visible;
			}
		}

		.titles-material {
			width: 260px;
			display: inline-block;
			vertical-align: top;
			.reset-password {
				padding-top: 8px;
				padding-bottom: 5px;

				.reset-button {
					@include primary-button;
				}
			}
			.dates-container {
				display: flex;
				> div {
					&:not(:last-child) {
						margin-right: 10px;
					}
					.date-picker-label {
						font-size: 11px;
						font-weight: unset;
						letter-spacing: unset;
						text-transform: unset;
						margin: 16px 0 8px 0;
					}
				}
				.date-separator {
					padding-top: 40px;
					font-weight: 500;
				}
			}
		}
	}
	.working-hours-table {
		width: 600px !important;
		input[type='number'] {
			width: 100%;
		}
		thead,
		tbody {
			tr {
				th,
				td {
					text-align: right;
					min-width: 100px;
					max-width: 100px;
				}
			}
		}
	}

	.hourly-cost {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 20px;
		.hourly-cost-label {
			margin-right: 8px;
		}
		.hourly-cost-input-container {
			width: 6em;
			border: 1px solid $app-border-color;
			border-radius: 3px;
			padding-right: 4px;
			input {
				outline: 0;
			}
		}
	}

	.skills {
		padding-left: 16px;
		h3 {
			margin: 0px;
			padding-left: 4px;
		}
	}

	.permissions {
		.permissions-info-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			.more-info-button {
				@include secondary-button;
				margin: 0 0 0 30px;
			}
		}
		.input-container {
			max-width: 500px;
		}
	}

	.harvest-settings {
		.harvest-description {
			margin: 12px 0;
		}
		.harvest-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			.harvest-header {
				margin-bottom: 0px;
				padding-left: 10px;
			}
		}
	}

	.util-settings {
		label {
			display: block;
			margin-bottom: 15px;

			> span {
				display: block;
				padding: 0 0 6px 2px;
			}
			input[type='text'],
			input[type='number'] {
				display: inline-block;
				margin-right: 5px;
				width: 50px;
			}
			.unit {
				display: inline;
			}
		}
	}

	.calendar-integration-settings {
		.input-wrapper {
			display: flex;
			width: 35rem;
			input {
				width: 100%;
			}
			.wrapper {
				flex: 1;
			}
			.copy-button {
				padding: 0px;
				margin-left: 10px;
				align-self: flex-end;
				background-color: transparent;
				border: none !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				flex: 0 0 24px;
				width: 24px;
				height: 24px;
				background-image: url('images/sections/insights/builder/copy.svg');
				&:hover {
					background-image: url('images/sections/insights/builder/copy-active.svg');
				}
			}
		}
		.section-border {
			padding: 16px;
		}
	}

	.github-integration-settings,
	.gitlab-integration-settings {
		.input-wrapper,
		.input-wrapper-focused {
			display: flex;
			width: 15rem;
			button {
				@include primary-button;
				align-self: flex-end;
				margin-left: 8px;
			}
		}
		.users {
			margin-top: 8px;
			max-width: 300px;
			:hover {
				background-color: $color-medium-gray;
				color: $color-purple-hover-active;
			}
			.github-user,
			.gitlab-user {
				cursor: pointer;
				img {
					cursor: pointer;
				}
				.name {
					cursor: pointer;
				}
			}
		}
		.github-user,
		.gitlab-user {
			height: 50px;
			width: auto;
			display: flex;
			margin-bottom: 4px;
			padding-left: 4px;
			padding-right: 8px;
			img {
				margin-right: 8px;
				align-self: center;
			}
			.name {
				height: auto;
				align-self: center;
			}
		}
		.github-user-container,
		.gitlab-user-container {
			display: flex;
			align-items: center;
			button {
				@include secondary-button;
			}
		}
	}
	.common-integration-settings {
		.common-integration-user-dropdown {
			margin-top: 8px;
			width: 300px;
		}
	}
}
.unit4-modal-content,
.github-modal-content,
.gitlab-modal-content {
	.github-user,
	.gitlab-user {
		font-size: $base-font-size;
		// height: 50px;
		width: auto;
		display: flex;
		margin-top: 8px;
		margin-left: 8px;
		img {
			margin-right: 8px;
			align-self: center;
		}
		.name {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: auto;
			align-self: center;
			.username {
				font-weight: $base-font-medium-weight;
				font-size: 11px;
			}
		}
	}
	.unit4-user {
		margin-top: 8px;
		margin-left: 8px;
	}
	.forecast-person {
		margin-top: 8px;
		margin-bottom: 8px;
		margin-left: 8px;
		.person-info {
			font-size: $base-font-size;
		}
	}
}
