.insights-empty-state-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 42px 0 0 0;
	text-align: center;
	.info {
		font-family: $heading-font-family;
		font-size: 32px;
		margin: 20px;
		max-width: 100%;
		word-wrap: break-word;
		&.small-text {
			font-size: 24px;
		}
	}
	img {
		margin-top: 42px;
		height: 250px;
		width: 200px;
	}
}
