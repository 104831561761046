.integration-sync-clients-modal-content {
	display: flex;
	flex-direction: column;
	width: 500px;
	overflow: hidden;
	.integration-sync-clients-modal-description {
		margin-bottom: 8px;
	}
	.integration-clients {
		width: 500px;
		.integration-client {
			width: 100%;
			display: flex;
			align-items: center;
			input[type='checkbox'] {
				margin-right: 8px;
			}
		}
	}
}
