.calendar-logos-wrapper {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	flex-direction: column;
	width: 98px;
}

.main-body {
	display: flex;
	flex-direction: column;
	padding-left: 40px;
	.title {
		padding-left: 20px;
		font-size: 17px;
		padding-bottom: 25px;
	}
	.input-fields {
		padding-left: 40px;
	}
}
