.settings-rate-cards {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 66px;
		}
	}
	.rate-cards {
		.top-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.description {
				margin: 0;
			}
			.add-client-button {
				@include primary-button;
			}
		}
		@include standard-table;
		.column-default {
			width: 40px;
		}
		.column-default,
		.default {
			text-align: center;
			padding-right: 64px;
		}
		.header-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		table {
			width: 100%;
		}
		tbody {
			tr {
				.gear-menu {
					position: relative;
					margin-left: auto;
					&:focus {
						outline: none;
					}
					> span {
						cursor: pointer;
						margin-top: 4px;
						display: inline-block;
						height: 15px;
						width: 30px;
						background: url('images/sections/project/settings/navigation-show-more-horizontal.svg') no-repeat;
						background-position: center;
					}
					.gear-menu-drop-down {
						position: absolute;
						border: 1px solid $app-border-color;
						border-bottom-left-radius: 3px;
						border-bottom-right-radius: 3px;
						background-color: $color-white;
						width: 120px;
						z-index: 10;
						ul {
							padding: 0px;
							margin: 0px;
							list-style: none;
						}
						&.collapsed {
							display: none;
						}
						&.expanded {
							display: block;
						}
						.settings-drop-down {
							color: $color-black;
							.option {
								background-color: $color-white;
								cursor: pointer;
								padding: 10px;
								&:hover {
									color: $color-purple-hover-active;
								}
								&.disabled {
									color: $input-placeholder-color-disabled;
									cursor: none;
								}
							}
						}
					}
				}
				&.row-rate-card {
					cursor: pointer;
					.name {
						h3 {
							padding-top: 8px;
							margin: 0;
							font-size: 14px;
						}
					}
					img {
						padding: 10px;
					}
				}
			}
		}
		.rate-card-table {
			margin-top: 25px;
		}
	}
}
