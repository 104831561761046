@import 'project_section_header';
@import 'board_card_details_selector';
.project-actions {
	display: flex;
	flex: 0 0 41px;
	margin: 15px 66px 10px 66px;
	button {
		width: 108px;
	}
	.left-buttons-wrapper {
		margin-right: 8px;
		display: flex;
		flex-grow: 1;
		button {
			height: 38px;
		}
	}
	.bulk-select-and-filter {
		display: flex;
		flex-direction: row;
		margin-right: 66px;
		flex-grow: 0;
		.custom-button-container {
			outline: none;
		}
		.bulk-edit-container {
			margin-right: 8px;
		}
	}
}
.section-body-v2 {
	padding: 0px 66px;
	display: flex;
	flex: 1 1 auto;
}
