.settings-skills {
	&.section-content {
		margin: 0;
	}

	.section-body {
		padding: 0 24px;
	}

}
