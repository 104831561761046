.asana-import-status-table {
	@-webkit-keyframes rotating {
		from {
			-webkit-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(360deg);
			-o-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes rotating {
		from {
			-ms-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-webkit-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		to {
			-ms-transform: rotate(360deg);
			-moz-transform: rotate(360deg);
			-webkit-transform: rotate(360deg);
			-o-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	.status-cell {
		button {
			border: none;
		}

		img {
			margin-top: 4px;
			height: 20px;
			width: 20px;
		}

		.migration-failed-clipboard {
			outline: none;
			border-radius: 4px;
			background-color: #dcdcdc;

			&:hover {
				background-color: #535353;
			}
		}

		.migration-success-icon,
		.loading-icon {
			margin-left: 6px;
		}

		.loading-icon {
			-webkit-animation: rotating 2s linear infinite;
			-moz-animation: rotating 2s linear infinite;
			-ms-animation: rotating 2s linear infinite;
			-o-animation: rotating 2s linear infinite;
			animation: rotating 2s linear infinite;
		}
	}

	.project-column {
		cursor: pointer;
	}
}
